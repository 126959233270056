import dayjs from "dayjs"

const locale = 'de-DE'

const numberFormat = (value, precision = 2) => {
    if (Intl) {
        return new Intl.NumberFormat(locale, {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        }).format(value)
    }

    return value.toFixed(precision).replace('.', ',')
}

const priceFormat = (value, precision = 2, currency = '€') => {
    if (Intl) {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        }).format(value)
    }
    return numberFormat(value, precision) + ' ' + currency
}

const productPriceFormat = (product, prefix = '') => {
    if (product.freePickup) {
        return 'kostenlos'
    }

    let price = priceFormat(product.oneTimePrice / 100)
    if (prefix) {
        price = prefix + ' ' + price
    }
    return price
}

const percentFormat = (value, precision = 1) => {
    if (Intl) {
        return new Intl.NumberFormat(i18n.global.locale.value, {
            style: 'percent',
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        }).format(value / 100)
    }
    return numberFormat(value, precision) + ' %'
}

const units = {
    cent: ['Cent'],
    seconds: ['Sekunde', 'Sekunden'],
    minutes: ['Minute', 'Minuten'],
    hours: ['Stunde', 'Stunden'],
    weeks: ['Woche', 'Wochen'],
    months: ['Monat', 'Monate'],
    years: ['Jahr', 'Jahre'],
    mg: ['mg'],
    g: ['g'],
    kg: ['kg'],
    km: ['km'],
}

function filterUnit(value, unit = '') {
    const unitDef = units[unit] || units[unit + 's'] || ''
    return value + ' ' + unitDef[0]
}

function filterDate(date) {
    if (!date) {
        return ''
    }

    if (typeof date === 'string') {
        date = new Date(date)
    } else if (!(date instanceof Date)) {
        return ''
    }

    if (Intl) {
        return new Intl.DateTimeFormat(locale, {
            timezome: 'UTC',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(date)
    }

    return date.toLocaleDateString()
}

function firstUpperCase(str) {
    if (typeof str !== 'string') {
        console.warn('string expected', str)
        return str
    }

    return str.substring(0, 1).toUpperCase() + str.substring(1)
}

function formatDate(dateString, format) {

    return dayjs(dateString).format(format);

}; // eo formatDate()

export const useFilters = {
    number: (value, precision) => numberFormat(value, precision),
    price: (value, precision, currency) =>
        priceFormat(value, precision, currency),
    productPrice: productPriceFormat,
    percent: (value, precision) => percentFormat(value, precision),
    round: Math.round,
    ceil: Math.ceil,
    floor: Math.floor,
    abs: Math.abs,
    unit: filterUnit,
    date: filterDate,
    formatDate: formatDate,
    firstUpperCase,
}
